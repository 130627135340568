import React, { useEffect, useRef, useState } from "react";
import { admin } from "../../utils/EndPoint";
import { AgGridReact } from "ag-grid-react";

import 'ag-grid-enterprise'
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import axiosInstance from "../../utils/Axios";
import { Link } from "react-router-dom";

const Table = () => {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(); // Create a ref for the AgGridReact instance

  const [colDefs] = useState([
    { field: "id", filter: true },
    { field: "name", filter: true },
    { field: "categoryName", filter: true },
    { field: "amount_paid", filter: true },
    { field: "totalCount", filter: true },
    { field: "transaction_date", filter: true },
    { field: "transaction_id", filter: true },
    { field: "gstno", filter: true },
    { field: "email", filter: true },
    { field: "phoneNumber", filter: true },
    { field: "KMAMemberNumber", filter: true },
    { field: "organisation", filter: true },
    { field: "role", filter: true },
    { field: "checkin", filter: true },
  ]);

  useEffect(() => {
    axiosInstance
      .get(admin)
      .then((res) => {
        setRowData(res?.data?.admin_result);
        console.log(res?.data?.admin_result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Function to export data to Excel
  const onExportClick = () => {
    gridRef.current.api.exportDataAsExcel();
  };

  return (
    <div className="ag-theme-quartz w-full h-screen overflow-hidden overflow-y-scroll">
      {/* Button to trigger Excel export */}
      <div className="flex flex-col lg:flex-row items-center justify-between p-5 w-full ">
        <div className="">
          <img
            src={require("../../assets/KMA-Logo 2.png")}
            alt="Logo"
            className="flex items-center"
          />
        </div>

        <h1 className="my-3 text-2xl font-bold uppercase">
          Admin Register Panel
        </h1>
        <div className="w-full lg:w-fit gap-3 lg:gap-5 flex flex-col lg:flex-row">
          <button onClick={onExportClick} className="px-4 py-2 bg-red-500 text-white rounded w-full lg:w-fit">
            Export to Excel
          </button>
          <Link to={'https://airtable.com/app6VvCBmnfmp2Tc3/shrM6BYf30X59MMuI/tblK10mqyYp1zbtvF/viw4EkJOt3k6nt9ew?blocks=hide'} className="text-center px-4 py-2 bg-blue-500 text-white rounded w-full lg:w-fit">
            Group Registration
          </Link>
        </div>
      </div>

      {/* table */}
      <div className="h-full w-screen overflow-x-scroll p-5 ">
        <AgGridReact
          ref={gridRef} // Bind the gridRef to AgGridReact instance
          rowData={rowData}
          columnDefs={colDefs}
        />
      </div>

    </div>
  );
};

export default Table;
