import React from "react";

import { sideData } from "../../data/SidePostData";
import { useState } from "react";
import { Link } from "react-router-dom";

const SidePanel = () => {
  const [data, setData] = useState(sideData);
  const [select, setSelect] = useState("1");
  const [selectedData, setSelectedData] = useState(
    data.filter((items) => items.id === "1")
  );

  const clickHandler = (value) => {
    setSelect(value);
    setSelectedData(data.filter((items) => items.id === value))
  };
  return (
    <div className="hidden lg:block items-center md:w-[450px] z-50">
      <div className="">
        <img
          src={'/newSideBanner.jpg'}
          alt="side_image"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
};

export default SidePanel;
